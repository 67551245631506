import * as actionTypes from "../actions/ActionTypes"

const INITIAL_AUTH = {
  jwtToken : null
};


export default (state = INITIAL_AUTH, action) => {
  switch (action.type) {
    case actionTypes.SET_JWT_TOKEN:
      return {
        ...state,
        jwtToken: action.payload.jwtToken,
      };
    default:
      return state;
  }
};

