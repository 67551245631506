
import React, { useEffect, useState } from 'react'
import {TSolidBox} from "../../template/TComponents"


export default ({song, playSong, pauseSong, setPlayingSong, currentPlayingSong, playSongState, attributeExtractor}) => {
  const {id, title, artist, url, genre, score, state, scores} = attributeExtractor(song)
  const analyzed = state === 'analyzed'
  const [isPlaying, setPlaying] = useState(false)

  useEffect(() => {
      if (currentPlayingSong === url && playSongState){
          setPlaying(true)
      }else{
          setPlaying(false)
      }
  }, [currentPlayingSong, playSongState])


  const playHandler = (e) => {
      if (!isPlaying) {
          setPlayingSong(url)
          playSong()
          setPlaying(true)
      } else {
          pauseSong()
          setPlaying(false)
      }
  }

  return (
      <React.Fragment>
          <TSolidBox>
              <button className="bg-red-700 rounded-full text-white js-play-button p-4 cursor-pointer" data-song-src={url} data-song-id={id} id="slot-submitted-play" aria-label="Play" onClick={playHandler}>
                  {!isPlaying && <div className="border-transparent border-t-8 border-b-8 js-play-icon" style={{borderLeft: "solid 16px"}} />}
                  {isPlaying && <div className="js-pause-icon">
                      <div className="w-4 h-4 flex">
                          <div className="flex-grow bg-current"></div>
                          <div className="flex-grow"></div>
                          <div className="flex-grow bg-current"></div>
                      </div>
                  </div>}
              </button>  
              <div class="block ml-8">
                <div className="grid grid-cols-1 gap-4">
                    <span className="text-xl">{title}</span>
                </div> 
                <div className="grid grid-cols-1 gap-4">
                    <span className="text-l">{artist}</span>
                </div> 
                {analyzed && !scores && <div className="grid grid-cols-2 gap-4">
                    <span style={{whiteSpace: "nowrap"}}>{genre}</span>
                    <span style={{whiteSpace: "nowrap"}}>{score}%</span>
                </div>}
                {
                    analyzed && scores && 
                        <React.Fragment>
                            <br />
                            <span style={{fontWeight:'bold'}}>Scores:</span>
                            {scores.map((s) => {
                                let {genre, score} = s
                                genre = genre == "Electro / Dance" ? "EDM" : genre

                                return (
                                    <React.Fragment>
                                        <div className="grid grid-cols-2 gap-4">
                                            <span style={{whiteSpace: "nowrap"}}>{genre}</span>
                                            <span style={{whiteSpace: "nowrap"}}>{score}%</span>
                                        </div>      
                                    </React.Fragment>
                                )
                            })}
                    </React.Fragment>
                }
                {
                    !analyzed &&
                    <React.Fragment>
                        <br />
                        <span className="animate-pulse" style={{whiteSpace: "nowrap"}}>Analyzing...</span>
                    </React.Fragment>
                }
            </div>
          </TSolidBox>
          <div className="h-4"></div>
      </React.Fragment>
  )
}
