import { labels as englishLabels } from "./english";
import { labels as frenchLabels } from "./french";

export const getLabels = (language) => {
  switch (language) {
    case "english":
      return englishLabels;
    case "french":
      return frenchLabels;
    default:
      return englishLabels;
  }
};
