import {Amplify} from "aws-amplify";
import * as TComp from "./TComponents"
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getLabels } from "../../languages/language";
import { LanguageContextConsumer } from "../context/LanguageContext";

const NewRegistrationProcess = ({credentials, verticalSpacing, handleSubmitForNewRegistration, error, labels, updateInputValue}) => {

  const {email, password, confirm_password, terms_conditions} = credentials

  return (
    <TComp.TFlex>
    <TComp.TBox>
      <TComp.TFormText>Register</TComp.TFormText>
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel>{labels.label_email}</TComp.TLabel>
      <TComp.TInput type="email" value={email} onChange={(e) => updateInputValue('email', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel>{labels.label_password}</TComp.TLabel>
      <TComp.TInput type="password" value={password} onChange={(e) => updateInputValue('password', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel>{labels.label_confirm_password}</TComp.TLabel>
      <TComp.TInput type="password" value={confirm_password} onChange={(e) => updateInputValue('confirm_password', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TErrorBox error={error} />
      <TComp.TFlex>

        <div style={{border:'1px solid #aaa', backgroundColor: '#eee', borderRadius:'4px', padding: '5px'}}>
          <h4><b>Password requirements</b></h4>
          <div style={{marginLeft:'30px'}}>
          <ul style={{listStyleType:'circle', listStylePosition:'initial'}}>
            <li>Length must be at least 6 characters</li>
              <li>Contains at least 1 number</li>
                <li>Contains at least 1 uppercase letter</li>
                  <li>Contains at least 1 lowercase letter</li>
          </ul>
          </div>

        </div>
      </TComp.TFlex>
      <TComp.TVSpace height={verticalSpacing} />
      <div className="flex justify-between">
        <TComp.TInput type="checkbox" value={terms_conditions} onChange={(e) => updateInputValue('terms_conditions', e.target.value)}  /> {labels.terms_conditions}
      </div>

      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TJustifiedFlexBox>
        <TComp.TLink to="/login">Back to login</TComp.TLink>
        <TComp.TVSpace width="4" />
        <TComp.TBtnSubmit onClick={handleSubmitForNewRegistration}>Register</TComp.TBtnSubmit>
      </TComp.TJustifiedFlexBox>
    </TComp.TBox>
  </TComp.TFlex>
)
}

const ContinueRegistrationProcess = ({credentials, verticalSpacing, handleSubmitForExistingRegistration, error, labels, updateInputValue}) => {
  const {email, code} = credentials

  return (
  <TComp.TFlex>
    <TComp.TBox>
      <TComp.TFormText>Registration Confirmation</TComp.TFormText>
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-email">{labels.label_email}</TComp.TLabel>
      <TComp.TInput value={email} onChange={(e) => updateInputValue('email', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-code">Code</TComp.TLabel>
      <TComp.TInput value={code} onChange={(e) => updateInputValue('code', e.target.value)}   />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TJustifiedFlexBox>
        <TComp.TLink to="/login">Back to login</TComp.TLink>
        <TComp.TVSpace width="4" />
        <TComp.TBtnSubmit onClick={handleSubmitForExistingRegistration}>Submit</TComp.TBtnSubmit>
      </TComp.TJustifiedFlexBox>
      <TComp.TErrorBox error={error} />
    </TComp.TBox>
  </TComp.TFlex>
)}

const Register = ({user, sendRegistrationConfirmation, language}) => {
  const {registration} = user
  const [registrationCredentials, setRegistrationCredentials] = useState({email: "", password: "", confirm_password: "", terms_conditions: false})
  const labels = getLabels(language);
  const [error, setError] = useState("");
  const history = useHistory()

  const isConfirmationCodeSent = registration && registration.confirmationCodeSent

  const registerHandler = async (e) => {
    e.preventDefault();

    const {email, password, confirm_password, terms_conditions} = registrationCredentials

    if (!email || !password) {
      setError("Please fill the form")
      return;
    }

    if (password !== confirm_password) {
      setError("Your confirm password & password don't match")
      return;
    }

    if (!terms_conditions) {
      setError("Please validate your terms & conditions")
      return;
    }

    try {
      const response = await Amplify.Auth.signUp({username: email, password})

      //console.log(response)

      const {DeliveryMedium, Destination} = response.codeDeliveryDetails
      sendRegistrationConfirmation(DeliveryMedium, Destination)
    } catch(err) {
      setError(err.message);
    }

  }

  const registrationConfirmationHandler = async (e) => {
    e.preventDefault();

    const {email, code} = registrationCredentials

    if (!email || !code) {
      setError("Please fill the form")
      return;
    }

    try {
      await Amplify.Auth.confirmSignUp(email, code)
      history.push('/login')
    }catch(err){
      console.error(err)
    }

  }

  const setCredential = (key, val) => {
    setError(null)
    setRegistrationCredentials({...registrationCredentials, [key]: val})
  }

  return (
    !isConfirmationCodeSent ?
    <NewRegistrationProcess credentials={registrationCredentials} error={error} handleSubmitForNewRegistration={registerHandler} updateInputValue={setCredential} labels={labels} verticalSpacing={4} /> :
    <ContinueRegistrationProcess credentials={registrationCredentials} error={error} handleSubmitForExistingRegistration={registrationConfirmationHandler} updateInputValue={setCredential} labels={labels} verticalSpacing={4} />
  );
};

export default (props) => <LanguageContextConsumer Component={Register} {...props} />;
