import React from "react";
import "./App.css";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Login from "./components/auth/Login";
import NotFound from "./components/NotFound";
import Main from "./components/layouts/Main";
import Register from "./components/auth/Register";
import {LanguageContextProvider} from "./components/context/LanguageContext";
import {connect} from "react-redux";
import Authenticator from "./components/auth/Authenticator";
import {Amplify} from "aws-amplify";
import aws from "./configs/Aws"
import {setJwtToken, setUser, sendPasswordConfirmation, logOut, setPortal, loadingUser, addSong, sendRegistrationConfirmation} from "./store/actions/Actions"
import ForgotPassword from "./components/auth/ForgotPassword";
import Dashboard from "./components/layouts/Dashboard";
import Leaderboard from "./components/layouts/Leaderboard";
import axios from "axios";

Amplify.configure(aws)

/*********************************************/
/**
 * ENABLING API DEBUG ON REQUESTS
 */
axios.defaults.withCredentials = true
//document.cookie = 'XDEBUG_TRIGGER=trigger'
/*********************************************/


function App({
                 user, auth, setJwtToken, setUser, setPortal, sendPasswordConfirmation, sendRegistrationConfirmation,
                 logOut, songsCount, loadingUser, addSong, portal
             }) {

    const RenderAuthenticator = ({children}) => (
        <Authenticator user={user} setJwtToken={setJwtToken} setPortal={setPortal} setUser={setUser} loadingUser={loadingUser}>
            {children}
        </Authenticator>
    )

    const RenderMainLayout = ({children}) => (
        <Main user={user} logout={logOut}>
            {children}
        </Main>
    )

    // FIXME replace LanguageContextProvider with react-intl
    return (
        <Router>
            <LanguageContextProvider>
                <Switch>
                    <Route path="/" exact>
                        <RenderAuthenticator>
                            <RenderMainLayout>
                                <Dashboard user={user} songsCount={songsCount} addSong={addSong}/>
                            </RenderMainLayout>
                        </RenderAuthenticator>
                    </Route>
                    <Route path="/leaderboard" exact>
                        <RenderAuthenticator>
                            <RenderMainLayout>
                                <Leaderboard portal={portal}/>
                            </RenderMainLayout>
                        </RenderAuthenticator>
                    </Route>
                    <Route path="/login" exact>
                        <RenderAuthenticator>
                            <RenderMainLayout>
                                <Login user={user} auth={auth} setUser={setUser} setPortal={setPortal}/>
                            </RenderMainLayout>
                        </RenderAuthenticator>
                    </Route>
                    <Route path="/register" exact>
                        <RenderAuthenticator>
                            <RenderMainLayout>
                                <Register user={user} sendRegistrationConfirmation={sendRegistrationConfirmation}/>
                            </RenderMainLayout>
                        </RenderAuthenticator>
                    </Route>
                    <Route path="/forgot-password" exact>
                        <RenderMainLayout>
                            <ForgotPassword auth={auth} sendPasswordConfirmation={sendPasswordConfirmation} user={user}/>
                        </RenderMainLayout>
                    </Route>
                    <Route component={NotFound}/>
                </Switch>
            </LanguageContextProvider>
        </Router>
    );
}

export default connect(state => {
    const isFirstTimeForUser = () => {
        if (!state.user.isLoaded) return false

        const {currentUser} = state.user
        if (currentUser && currentUser.attributes && currentUser.attributes.uploadsAvailable > 0) {
            return false
        }

        if (!state.songs.loaded) return false
        const {songs} = state.songs

        if (songs.length > 0) return false

        return true
    }

    return ({
        user: state.user,
        auth: state.auth,
        portal: state.portal,
        firstTime: isFirstTimeForUser()
    })
}, {setJwtToken, setUser, sendPasswordConfirmation, logOut, setPortal, loadingUser, addSong, sendRegistrationConfirmation})(App);
