import * as actionTypes from "./ActionTypes";


export const setJwtToken = (jwtToken) => {
    return {
        type: actionTypes.SET_JWT_TOKEN,
        payload: {
            jwtToken: jwtToken
        },
    };
};


export const setPortal = (portal) => {
    return {
        type: actionTypes.SET_PORTAL,
        payload: {
            ...portal
        }
    }
}

export const setSongs = (response) => {
    let payload = {
        data: response.data,
        paginator: {
            links: response.links,
            meta: response.meta,
        }
    };

    return {
        type: actionTypes.SET_SONGS,
        payload: payload
    }
}

export const addSong = (song) => {
    return {
        type: actionTypes.ADD_SONG,
        payload: song
    }
}

export const songsRedraw = (redraw) => {
    return {
        type: actionTypes.SONGS_REDRAW,
        payload: {
            songsRedraw: redraw
        }
    }
}
export const resetPaginatorHasChanged = () => {
    return {
        type: actionTypes.RESET_PAGINATOR_HAS_CHANGED,
        payload: {
            paginatorHasChanged: false
        }
    }
}

export const removeSongs = (song) => {
    return {
        type: actionTypes.REMOVE_SONG,
        payload: song
    }
}


export const setPlayerSong = (playerSong, playlist, playerSongParentId) => {
    return {
        type: actionTypes.SET_PLAYER_SONG,
        payload: {
            playerSong: playerSong,
            playlist: playlist
        }
    }
}

export const setPlayerState = (playerState) => {
    return {
        type: actionTypes.SET_PLAYER_STATE,
        payload: playerState
    }
}

export const setNextSong = () => {
    return {
        type: actionTypes.SET_NEXT_SONG,
        payload: {}
    }
}

export const setDnaToken = (token) => {
    return {
        type: actionTypes.SET_DNA_TOKEN,
        payload: token
    }
}

export const setUser = (data, referenceId) => {
    return {
        type: actionTypes.SET_USER,
        payload: {
            isLoaded: true,
            currentUser: data && referenceId && {...data, referenceId}
        },
    };
};

export const loadingUser = () => {
    return {
        type: actionTypes.LOADING_USER,
        payload: {
            loading: true
        },
    };
};

export const setLeaderboard = (songs) => {
    return {
        type: actionTypes.SET_LEADERBOARD,
        payload: {
            songs
        }
    }
}

export const setSuccessMessage = (message) => {
    return {
        type: actionTypes.SET_SUCCESS_MESSAGE,
        payload: {
            message: message,
        },
    };
};

export const setErrorMessage = (message) => {
    return {
        type: actionTypes.SET_ERROR_MESSAGE,
        payload: {
            message: message,
        },
    };
};

export const sendRegistrationConfirmation = (confirmationMedium, confirmationDestination) => {
    return {
        type: actionTypes.SEND_REGISTRATION_CONFIRMATION,
        payload: {
            registrationProcess: true,
            confirmationCodeSent: true,
            confirmationMedium: confirmationMedium,
            confirmationDestination: confirmationDestination
        }
    }
}

export const logOut = () => {
    return {
        type: actionTypes.LOG_OUT,
        payload: {
            users: [],
        },
    };
};

export const sendPasswordConfirmation = (confirmationMedium, confirmationDestination) => {
    return {
        type: actionTypes.SEND_PASSWORD_CONFIRMATION,
        payload: {
            forgotPassword: true,
            confirmationCodeSent: true,
            confirmationMedium: confirmationMedium,
            confirmationDestination: confirmationDestination
        }
    }
}
