import React from "react";
import SearchResult from "./SearchResult";

export default function SearchResults(props) {

    if (props.results && props.results.length > 0) {

        return (

            <div className="relative z-20 text-black">
                {props.isOpen ?
                    <div className="absolute shadow w-full">
                        {props.results.map(function (result, index) {
                            return <SearchResult key={index} result={result} handler={props.handler}/>
                        })}
                    </div>
                    : null}
            </div>
        );
    }

    return (
        <></>
    );
}
