import React from "react";
import {addCatalog} from "../../libraries/DnaApi";
import {useDispatch} from "react-redux";
import {addSong} from "../../store/actions/Actions";

function SearchResult(props) {

    const dispatch = useDispatch();

    function addCatalogFromSearchResults(song) {
        props.handler({loading: true});
        addCatalog(song).then((response) => {
            dispatch(addSong(response));
            props.handler({loading: false});
        });
        document.getElementById("song-search").value = "";
    }

    function coverUrl() {
        return props.result.cover
    }

    return (
        <>
            <div className="bg-white border border-gray-300 flex items-center hover:bg-gray-100" onClick={() => addCatalogFromSearchResults(props.result.id)}>
                <img src={coverUrl()} alt="" className="w-10 h-10"/>
                <div className="p-2">{props.result.artist} - {props.result.title}</div>
            </div>
        </>
    )
}

export default SearchResult;