import React from 'react'
import {Transition} from '@headlessui/react'
import {useState} from 'react'


const TDropdown = ({children, ...props}) => {
    const [open, setOpen] = useState(false)
    const modifyDropdownState = (e) => {
        setOpen(!open)
    }
    const closeDropdown = () => setOpen(false)

    return (
        <div className="relative inline-block text-left">
            <div>
        <span className="rounded-md shadow-sm">
          <button type="button" aria-haspopup={open} aria-expanded={open} onClick={modifyDropdownState}
                  className="py-2 text-gray-200 hover:text-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="mr-1 ml-2 h-5 w-5" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
          </button>
        </span>
            </div>


            <Transition
                show={open}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                {(ref) => {
                    return (
                        <div ref={ref} className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
                            <div className="rounded-md bg-white shadow-xs">
                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {children(closeDropdown)}
                                </div>
                            </div>
                        </div>
                    )
                }}

            </Transition>
        </div>
    )
}

export const TDropdownItem = ({children, as = "a", onClick, ...props}) => {
    return (
        React.createElement(as, {
            className: "block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900",
            role: "menuitem",
            onClick: onClick,
            ...props
        }, children)
    )
}

export default TDropdown