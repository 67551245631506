import * as actionTypes from "./../actions/ActionTypes";

const initialUserState = {
  isLoaded: false,
  isLoading: false,
  currentUser: null,
  forgotPassword: null,
  registration: null,
  dnaToken: null,
};

export default (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        isLoaded: action.payload.isLoaded,
        isLoading: false,
        currentUser: action.payload.currentUser,
        forgotPassword: null,
        registration: null,
        dnaToken: action.payload.dnaToken
      };
    case actionTypes.LOADING_USER:
      return {
        ...state,
        isLoading: true
      };
    case actionTypes.SEND_PASSWORD_CONFIRMATION:
      return {
        ...state,
        forgotPassword: action.payload
      }
    case actionTypes.SEND_REGISTRATION_CONFIRMATION:
      return {
        ...state,
        registration: action.payload
      }
    case actionTypes.SET_DNA_TOKEN:
      return {
        ...state,
        dnaToken: action.payload
      }

    default:
      return state;
  }
};