import React, {useEffect, useState} from 'react'
import 'react-h5-audio-player/lib/styles.css';
import {connect, useDispatch} from "react-redux";
import AudioPlayer from 'react-h5-audio-player';
import {addSong, setPlayerSong, songsRedraw} from "../../store/actions/Actions";
import {addCatalog} from "../../libraries/DnaApi";
import PlayerLoader from "../loaders/PlayerLoader";

function HitlabPlayer({songArtistTitle, playingSong, playerQueue}) {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [parentId, setParentId] = useState(null);

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (playingSong.id) {
            setIsLoading(true);
            if (playingSong.parentId) {
                setParentId(playingSong.parentId);
            }
            setIsLoading(false);
        }
    }, [playingSong]);

    const previous = () => {
        let index = Array.isArray(playerQueue) ? playerQueue.findIndex((song) => song.id === playingSong.id) : 0;
        let previousIndex = (index - 1) % playerQueue.length;//playerQueue.length - index - 1;
        if (previousIndex < 0) {
            previousIndex = (playerQueue.length - 2) - previousIndex;
        }

        if (playerQueue && playerQueue[previousIndex]) {
            if (playingSong.parentId) {
                playerQueue[previousIndex].parentId = playingSong.parentId;
            }
            dispatch(setPlayerSong(playerQueue[previousIndex], playerQueue));
        }
    }

    function next() {
        let index = Array.isArray(playerQueue) ? playerQueue.findIndex((song) => song.id === playingSong.id) : 0;
        let nextIndex = (index + 1) % playerQueue.length;

        if (playerQueue && playerQueue[nextIndex]) {
            if (playingSong.parentId) {
                playerQueue[nextIndex].parentId = playingSong.parentId;
            }
            dispatch(setPlayerSong(playerQueue[nextIndex], playerQueue));
        }
    }

    return (
        <div className="flex flex-row bg-black text-white">
            <div className="flex-none w-20 h-20 sm:w-36 sm:h-36">
                {isLoading
                    ?
                    <PlayerLoader width={100} height={100} message="Fetching song data"/>
                    :
                    <>
                        {playingSong.cover
                            ?
                            <div><img src={playingSong.cover} alt={playingSong.artist_title} className="object-scale-down"/></div>
                            :
                            <div className="m-4">
                                <img src="/images/logo/logo-hitlab.png"
                                     className="grayscale object-scale-down"
                                     alt={playingSong.artist_title}/>
                            </div>


                        }
                    </>
                }
            </div>
            <div className="flex-grow">
                <div className="mx-4 mt-2">
                    <div className="h-12">
                        <div>
                            <div className="text-xs sm:text-sm sm:text-lg font-bold truncate">{playingSong.artist}</div>
                            <div className="text-xs sm:text-sm truncate">{playingSong.title}</div>
                        </div>
                    </div>
                </div>
                <AudioPlayer
                    src={playingSong.song_url}
                    showSkipControls={true}
                    onEnded={() => next()}
                    onClickNext={() => next()}
                    onClickPrevious={() => previous()}
                />

            </div>
        </div>
    );
}

export default connect(state => {
    return {
        playingSong: state.playerSong,
        playerQueue: state.playerSong.playlist,
    };
})(HitlabPlayer)

