import {combineReducers} from "redux";
import * as actionTypes from "./../actions/ActionTypes";
import portal_reducer from "./PortalReducer"
import songs_reducer from "./SongsReducer"
import player_reducer from "./PlayerReducer"
import user_reducer from "./UsersReducer"
import leaderboard_reducer from "./LeaderboardReducer"
import auth_reducer from "./AuthReducer"

const initialAuthState = {successMessage: null, errorMessage: null};


const appReducer = combineReducers({
    user: user_reducer,
    auth: auth_reducer,
    //portal: portal_reducer,
    songs: songs_reducer,
    playerSong: player_reducer,
    //leaderboard: leaderboard_reducer
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.LOG_OUT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
