import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from "react-redux";
import ReactTooltip from "react-tooltip";
import {deleteSong as deleter, fetchData} from "../../libraries/DnaApi";
import {removeSongs, setPlayerSong, setSongs} from "../../store/actions/Actions";
import {isMobileSafari, isSafari} from "react-device-detect";
import HitlabLoader from "../loaders/HitlabLoader";
import useInterval from "../../hooks/useInterval";

function SongCard({song, playerSong}) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState('Analyzing');
    const [retries, setRetries] = useState(0);
    const [similar, setSimilar] = useState({'songs': null, 'loading': true, 'offset': 0});//fixme


    if (similar.songs === null && song.latest_analysis && song.latest_analysis.status === "ANALYZED") {
        /**
         * Initial state for similars
         */
        setSimilar({...similar, songs: song.latest_analysis.similars, loading: false});
    }

    useInterval(() => {
            if (!song.latest_analysis || song.latest_analysis && song.latest_analysis.status === "ANALYZING") {
                /**
                 * Polling for similars
                 */
                setRetries(retries + 1);

                //todo : cancel request : https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/
                fetchData('/songs/' + song.id + '/similars').then(function (response) {
                    if (response && response.status) {
                        switch (response.status) {
                            case 200:
                                fetchData('/songs/' + song.id + '/latest-analysis').then(function (response) {
                                    song.latest_analysis = response.data;
                                    setSimilar({loading: false, songs: song.latest_analysis.similars});//fixme
                                })

                                break;
                            case 202:
                                if (retries >= 10) {
                                    setLoaderMessage('Processing is too long, the file might need to be re-encoded');
                                    setSimilar({loading: false, songs: {}});//fixme
                                }
                                break;
                            case 422:
                                setLoaderMessage('Error processing file, try re-encoding it');
                                console.error('Error processing the file, please try to re-encode');//fixme
                                break;
                            case 404:
                            default:
                                setLoaderMessage('Processing problem on server-side, please retry');
                                setSimilar({loading: false, songs: {}});//fixme
                                break;
                        }
                    } else {
                        console.error('unattended response', response);
                    }
                });
            }
        },
        10000, // delay in milliseconds
        similar.loading,  // set to false to stop polling
    );

    const onClickDeleteSong = (song) => {
        setLoading(true);
        deleter(song).then((response) => {
            setLoading(false);
            dispatch(removeSongs(song));
        });
    }

    function showSongName(song) {
        if (song.artist && song.title) {
            return song.artist + ' - ' + song.title;
        } else if (song.artist) {
            return song.artist;
        } else if (song.title) {
            return song.title;
        } else {
            return 'N/A';
        }
    }

    function playIt(playerSong) {
        dispatch(setPlayerSong(playerSong, similar.songs));
    }

    function isAutoplayEnabled() {
        return !(isSafari || isMobileSafari);
    }

    const onClickPlaySong = (songOrSimilar, parentId) => {
        if (parentId) {
            songOrSimilar.parentId = parentId;
        }

        if (!isAutoplayEnabled()) {
            /**
             * Important hack for Safari
             */
            let empty = new Audio("audio/empty.mp3");
            empty.play().then(_ => {
                empty.pause();
                empty.currentTime = 0;

                dispatch(setPlayerSong(songOrSimilar, similar.songs));
            });
        } else {
            dispatch(setPlayerSong(songOrSimilar, similar.songs));
        }
    }


    function showPreviousSimilar() {
        if (similar.offset > 0) {
            setSimilar({...similar, offset: similar.offset - 12});
        }
    }

    function showNextSimilar() {
        if (similar.offset < similar.songs.length - 14) {
            setSimilar({...similar, offset: similar.offset + 12});
        }
    }

    return (
        <>
            {loading
                ? <HitlabLoader height={50} message="Removing song"/>
                : <div className={"mb-4 border border-gray-300 rounded-md shadow-md " + (song.id === playerSong.parentId ? 'bg-gray-200' : 'bg-gray-50')}>
                    <div className="flex items-center p-1 border-b border-gray-300">
                        <div className={"relative cursor-pointer hover:opacity-50 " + (song.type === 'song' && "border-l-4 border-hitlab-red")}
                             onClick={() => onClickPlaySong(song, null)}>
                            <div className="absolute p-4 opacity-0 hover:opacity-100">
                                <i className="fa fa-play text-white"/>
                            </div>
                            <img src={song.cover} alt="" className="w-12 h-12 object-scale-down p-1 rounded-lg hover:opacity-50"/>
                        </div>
                        <div className="p-1 relative flex-1">
                            <p className="text-sm truncate">{song.artist_title}
                                {song.type === 'song' && song.latest_analysis && song.latest_analysis.status === "ANALYZED" &&
                                    <span className={"font-bold text-red-600"}>&nbsp;&nbsp;&nbsp;&nbsp;( {song.latest_analysis.score} % )</span>
                                }
                            </p>
                            <p className="text-xs text-gray-400">{song.genre}</p>
                        </div>
                        <div className="p-2 relative right-0">
                            <button onClick={() => onClickDeleteSong(song)}
                                    title="Remove from shortlist"
                                    className="relative  top opacity-50 hover:opacity-100 bg-gray-400 hover:bg-red-600 text-sm text-white px-2 py-1 rounded-md">
                                <i className="fa fa-trash"/>
                            </button>
                        </div>
                    </div>

                    <footer className="text-gray-400">
                        {!similar.loading && similar.songs.length > 0 ?
                            <div
                                className="flex overflow-auto scrollbar-thin scrollbar-rounded-md scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 touch-auto">
                                {Array.isArray(similar.songs) && similar.songs.map((similar) => (
                                    <div className="flex-none p-1 first:pl-2 last:pr-2" key={'similar' + similar.id}>
                                        <div className=""
                                             title={similar.artist_title + ' (' + (similar.proximity * 100).toFixed(1) + ')'}
                                             data-tip={similar.artist_title + ' (' + (similar.proximity * 100).toFixed(1) + ')'}>
                                            <div
                                                className={"relative cursor-pointer border border-gray-300 px-1 py-1 hover:opacity-50 " + (song.id === playerSong.parentId && similar.id === playerSong.id ? 'bg-red-600' : 'bg-white')}
                                                onClick={() => onClickPlaySong(similar, song.id)}>
                                                <div className="absolute p-1 opacity-0 hover:opacity-100">
                                                    <i className="fa fa-play text-white ml-2"/>
                                                </div>
                                                <img src={similar.cover} alt="" className="w-8 h-8  rounded-sm"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            : <div className="flex-1 text-center">
                                {song.type === 'catalog' ?
                                    <div>No similar found</div>
                                    :
                                    <HitlabLoader inline height={20} type="BallTriangle" message={loaderMessage}/>
                                }
                            </div>
                        }
                    </footer>
                </div>
            }
        </>
    );
}

export default connect((state, ownProps) => {
        return {
            playerSong: state.playerSong
        };
    }
)
(SongCard)