import React from "react";
import {useState} from "react";
import {Amplify} from "aws-amplify";
import {useDispatch} from "react-redux";
import {getLabels} from "../../languages/language";
import {NavLink, useHistory} from "react-router-dom";
import {setDnaToken, setPlayerSong} from "../../store/actions/Actions";
import {LanguageContextConsumer} from "../context/LanguageContext";

const Login = (props) => {
    const {language, setUser} = props
    const [newLoginCredentials, setNewLoginCredentials] = useState({email: null, password: null})
    const {label_email} = getLabels(language);
    const [error, setError] = useState(null);
    const history = useHistory()

    const dispatch = useDispatch();
    const loginHandler = (e) => {
        const {email, password} = newLoginCredentials

        if (!email || !password) {
            setError("Please fill the form")
            return;
        }

        Amplify.Auth.signIn(email, password)
            .then(data => {
                //console.log(data);
                setUser(data, data.attributes.sub);
                window.location.pathname = '/'
            })
            .catch(err => {
                setError(err.message);
            });
    }

    const setCredential = (key, val) => {
        setError(null)
        setNewLoginCredentials({...newLoginCredentials, [key]: val})
    }

    return (
        <div className="flex-grow" role="main">
            <div className="max-w-md p-8 mx-auto flex flex-col">
                <div className="block text-2xl font-display">Login</div>
                <div className="h-4"></div>
                <label htmlFor="js-email">{label_email}</label>
                <input
                    className="rounded px-4 py-2 border border-gray-500"
                    id="js-email"
                    name="email"
                    type="email"
                    onChange={e => setCredential('email', e.target.value)}
                />
                <div className="h-4"></div>
                <label htmlFor="js-password">Password</label>
                <input
                    className="rounded px-4 py-2 border border-gray-500"
                    id="js-password"
                    name="password"
                    type="password"
                    onChange={e => setCredential('password', e.target.value)}
                />
                <div className="h-4"></div>
                <NavLink
                    className="text-red-700 font-medium cursor-pointer py-2 px-0"
                    to="/forgot-password"
                >
                    I forgot my password
                </NavLink>
                <div className="h-4"></div>
                <div className="flex justify-between">
                    <NavLink
                        to="/register"
                        className="text-red-700 font-medium cursor-pointer py-2 px-0"
                    >
                        Register
                    </NavLink>
                    <div className="w-4"></div>
                    <button
                        className="bg-red-700 rounded text-white font-medium cursor-pointer px-4 py-2"
                        onClick={loginHandler}
                        type="submit"
                    >
                        Login
                    </button>
                </div>

                {error &&
                    <div className="flex justify-between">
                        <p
                            className="block text-gray-700 text-center bg-gray-400 px-4 py-2"
                            id="js-message"
                        >
                            {error}
                        </p>
                    </div>
                }
            </div>
        </div>
    );
};

export default (props) => <LanguageContextConsumer Component={Login} {...props} />;
