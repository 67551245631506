import React from "react";
import { NavLink } from "react-router-dom";

export const TFlex = ({children}) => (
  <div role="main" className="flex-grow">{children}</div>
  )

export const TBox = ({children}) => (
  <div className="max-w-md p-8 mx-auto flex flex-col">{children}</div>
)

export const TFormText = ({children}) => (
  <div className="block text-2xl font-display">{children}</div>
)

export const TVSpace = ({width = 4, height = 4}) => {
    let c = ""
    if (width) c += "w-"+width
    if (height) c += " h-"+height
    
    return (
    <div className={c}></div>
  )}

 export const TLabel = ({htmlFor, children}) => (
    <label htmlFor={htmlFor}>{children}</label>
  )

 export const TInput = (props) => (
    <input className="rounded px-4 py-2 border border-gray-500" {...props}  />
  )

 export const TBtnSubmit = (props) => (
    <button type="submit" className="bg-red-700 rounded text-white font-medium cursor-pointer px-4 py-2" {...props} />
  )

export const TJustifiedFlexBox = ({children}) => (
    <div className="flex justify-between">{children}</div>
  )

export const TErrorBox = ({error}) => {
  return (error &&
      <p
        className="block text-gray-700 text-center bg-gray-400 px-4 py-2"
        id="js-message"
        >
        {error}
      </p>)   
}

export const TLink = (props) => (
  <NavLink as="a" className="text-red-700 font-medium cursor-pointer py-2 px-0" {...props} />
)