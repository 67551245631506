import React, {useEffect, useState} from 'react'
import {TFlex, TContainer, TBlock, TPrimaryHeading, TSecondaryHeading, TEmptySpace, TSolidBox} from "./../template/TComponents"
import {fetchData} from "./../../libraries/DnaApi"
import {connect} from 'react-redux'
import {setLeaderboard} from "./../../store/actions/Actions"
import HitlabPlayer from '../players/HitlabPlayer'
import List from '../songs/backup/List'

const Leaderboard = ({portal, isLoaded, songs, setLeaderboard}) => {

    const [playingSongUrl, setPlayingSongUrl] = useState(null)
    const [playSongState, setPlaySongState] = useState(false)

    const playSong = () => {
        setPlaySongState(true)
    }
    const pauseSong = () => {
        setPlaySongState(false)
    }

    useEffect(() => {
        if (!isLoaded) {
            fetchData(portal.leaderBoard)
                .then(data => {
                    setLeaderboard(data.attributes.songs)
                }).catch(e => console.error(e))
        }
    }, [isLoaded])

    return (
        <TFlex>
            <TContainer>
                <TBlock>
                    <TPrimaryHeading>Emerging Artists Showcase</TPrimaryHeading>
                    <TEmptySpace height={4}/>
                    <TSecondaryHeading>Leaderboard</TSecondaryHeading>
                    <TEmptySpace height={4}/>
                </TBlock>

                <TBlock>
                    <HitlabPlayer play={playSongState} src={playingSongUrl}/>
                </TBlock>

                <TBlock loading={!isLoaded}>
                    <List songs={songs}
                          loadingText="Loading uploaded songs"
                          loading={!isLoaded}
                          playSong={playSong}
                          pauseSong={pauseSong}
                          setPlayingSong={setPlayingSongUrl}
                          currentPlayingSong={playingSongUrl}
                          playSongState={playSongState}
                          attributeExtractor={(song) => ({...song, scores: null, state: 'analyzed'})}
                    />

                    {/* {songs && songs.map(song => {
                const {attributes} = song
                const {artist, score, title} = attributes
                
                return (
                  <TSolidBox style={{display: "block"}}>
                      <div className="tw-clearfix">
                        <span className="tw-text-xl tw-float-left">{title}</span>
                        <span style={{whiteSpace: "nowrap"}} className="tw-float-right tw-text-2xl">{score}%</span>
                      </div>
                      <div className="tw-clearfix">
                        <span className="tw-text-l tw-float-left">{artist}</span>
                      </div>
                  </TSolidBox>
                )
              })} */}
                </TBlock>
            </TContainer>
        </TFlex>
    )
}


export default connect(state => ({
    isLoaded: state.leaderboard.isLoaded,
    songs: state.leaderboard.songs
}), {setLeaderboard})(Leaderboard)