import React, {useCallback, useState} from 'react';
import {MeiliSearch} from 'meilisearch'
import SearchResults from './SearchResults';
import useClickOutside from "../../hooks/useClickOutside";
import {useDropzone} from 'react-dropzone'
import {prepareS3Upload, search, storeSong, uploadToS3} from "../../libraries/DnaApi";
import {addSong} from "../../store/actions/Actions";
import {useDispatch} from "react-redux";
import HitlabLoader from "../loaders/HitlabLoader";

export default function SongSearch() {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setLoading(true);

        acceptedFiles.forEach(acceptedFile => {
            // Request a signed url from the server to upload the audio on S3
            prepareS3Upload(acceptedFile).then((response) => {
                const {s3UploadBucket, s3UploadFields, s3Url, filename} = response.data;

                uploadToS3(s3UploadBucket, s3UploadFields, acceptedFile)
                    .then(response => {
                        storeSong(s3Url, filename).then(response => {
                            dispatch(addSong(response));
                            setLoading(false);
                        });
                    }).catch(err => {
                    console.error(err)
                })
            }).catch(e => {
                console.error(e);
            });
        })
    }, [])

    const [results, setResults] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    let resultList = useClickOutside(() => {
        setIsOpen(false);
    }, 5000);

    function onSearchInputChange(event) {
        search(event.target.value)
            .then(response => {
                setResults(response.hits);
                setIsOpen(true);
            })
            .catch(err => {
                setIsOpen(false);
            });
    }

    function toggleVisibility({loading}) {
        setLoading(loading);
        if (!loading) {
            setIsOpen(!isOpen);
        }
    }

    return (
        <>
            {loading
                ? <HitlabLoader type="Oval" height={20} width={50} message={'Adding song...'}/>
                :
                <div className="w-4/5 sm:w-3/4">
                    <div className="bg-white rounded-md flex">
                        <input
                            id="song-search"
                            type="text"
                            name="song-search"
                            onChange={event => onSearchInputChange(event)}
                            className="block w-3/5 sm:w-3/4 text-black p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-xs sm:text-sm z-10"
                            placeholder="Search for a song"
                            autoComplete="off"
                        />

                        <div {...getRootProps()} className="text-gray-400 w-2/5 sm:w-1/4 ">
                            <input {...getInputProps()} />
                            <div className="text-xs sm:text-sm text-center mt-2">Upload a file</div>
                        </div>
                    </div>
                    <div ref={resultList}>
                        <SearchResults results={results} isOpen={isOpen} handler={toggleVisibility}/>
                    </div>
                </div>
            }
        </>
    )

}