import React from "react";
import { LanguageContextConsumer } from "../context/LanguageContext";
import { getLabels } from "../../languages/language";
import { useState } from "react";
import {Amplify} from "aws-amplify";
import * as TComp from "./TComponents"
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const NewRecoveryProcess = ({credentials, verticalSpacing, handleSubmitForNewConfirmation, error, labels, updateInputValue}) => {
  const {email} = credentials

  return (  
    <TComp.TFlex>
    <TComp.TBox>
      <TComp.TFormText>Forgot Password</TComp.TFormText>
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-email">{labels.label_email}</TComp.TLabel>
      <TComp.TInput value={email} onChange={(e) => updateInputValue('email', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TJustifiedFlexBox>
        <TComp.TLink to="/login">Back to login</TComp.TLink>
        <TComp.TVSpace width="4" />
        <TComp.TBtnSubmit onClick={handleSubmitForNewConfirmation}>Submit</TComp.TBtnSubmit>
      </TComp.TJustifiedFlexBox>
      <TComp.TErrorBox error={error} />
    </TComp.TBox>
  </TComp.TFlex>
)}

const ContinueConfirmationProcess = ({credentials, verticalSpacing, handleSubmitForExistingConfirmation, error, labels, updateInputValue}) => {
  const {email, code, password} = credentials
  
  return (
  <TComp.TFlex>
    <TComp.TBox>
      <TComp.TFormText>Forgot Password Confirmation</TComp.TFormText>
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-email">{labels.label_email}</TComp.TLabel>
      <TComp.TInput value={email} onChange={(e) => updateInputValue('email', e.target.value)}  />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-code">Code</TComp.TLabel>
      <TComp.TInput value={code} onChange={(e) => updateInputValue('code', e.target.value)}   />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TLabel htmlFor="js-password">Password</TComp.TLabel>
      <TComp.TInput value={password} type="password" onChange={(e) => updateInputValue('password', e.target.value)}   />
      <TComp.TVSpace height={verticalSpacing} />
      <TComp.TJustifiedFlexBox>
        <TComp.TLink to="/login">Back to login</TComp.TLink>
        <TComp.TVSpace width="4" />
        <TComp.TBtnSubmit onClick={handleSubmitForExistingConfirmation}>Submit</TComp.TBtnSubmit>
      </TComp.TJustifiedFlexBox>
      <TComp.TErrorBox error={error} />
    </TComp.TBox>
  </TComp.TFlex>
)}
const ForgotPassword = ({language, user, sendPasswordConfirmation}) => {
  const verticalSpacing = 4

  const labels = getLabels(language)

  const [credentials, setCredentials] = useState({email: "", code: "", newPassword: ""})
  const [error, setError] = useState(null)

  const history = useHistory()

  const [forgotPassword, setForgotPassword] = useState(null)

  useEffect(() => {
    const {forgotPassword} = user
    setForgotPassword(forgotPassword)
  }, [user])

  const updateInputValue = (key, value) => {
    setCredentials({...credentials, [key]: value})
  }

  const handleSubmitForNewConfirmation = async e => {
    setError(null);

    const {email} = credentials

    if (!email) {
      setError("Fill in your email address")
      return;
    }

    try {
      const response = await Amplify.Auth.forgotPassword(email)
      const {DeliveryMedium, Destination} = response.CodeDeliveryDetails
      sendPasswordConfirmation(DeliveryMedium, Destination)
    }catch(err){
      console.error(err)
    }
  }

  const handleSubmitForExistingConfirmation = async e => {
    setError(null);

    const {email, code, password} = credentials

    if (!email || !code || !password) {
      setError("Fill in your email address")
      return;
    }

    try {
      const response = await Amplify.Auth.forgotPasswordSubmit(email, code, password)
      history.push('/login')
    }catch(err){
      console.error(err)
    }
  }


  return (
    (!forgotPassword && <NewRecoveryProcess
      credentials={credentials} 
      handleSubmitForNewConfirmation={handleSubmitForNewConfirmation} 
      verticalSpacing={verticalSpacing} 
      error={error} 
      updateInputValue={updateInputValue}
      labels={labels}
    />) || 
    <ContinueConfirmationProcess 
      credentials={credentials} 
      handleSubmitForExistingConfirmation={handleSubmitForExistingConfirmation} 
      verticalSpacing={verticalSpacing} 
      error={error} 
      updateInputValue={updateInputValue}
      labels={labels}
    />
    
  );
};

export default (props) => <LanguageContextConsumer Component={ForgotPassword} {...props} />;
