import React from 'react'
import SongCard from "./SongCard"
import {TBlock} from "../../template/TComponents"


const List = ({songs, loading=false, loadingText=null, songsNotFoundText="No songs found", ...props}) => {
  const renderedSlots = songs.map((song, k) =>  <SongCard 
                                      key={k+1} 
                                      song={song} 
                                      {...props}
                                    /> )
    return (
        <TBlock loading={loading} loadingText={loadingText}>
            {!loading && songs.length > 0 ? renderedSlots : songsNotFoundText}
        </TBlock>
    )
}

export default List
