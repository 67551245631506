import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, enabled = true) {
    const savedCallback = useRef(() => {});

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (typeof savedCallback.current !== "undefined") {
                savedCallback.current();
            }
        }

        let id: any;

        if (enabled && delay !== null) {
            id = setInterval(tick, delay);
        }

        return () => clearInterval(id);
    }, [delay, enabled]);
}