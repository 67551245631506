import * as actionTypes from "./../actions/ActionTypes"

const INITIAL_SONGS = {
    data: [],
    paginator: {
        links: {
            first: null,
            last: null,
            prev: null,
            next: null,
        },
        meta: {
            current_page: 1,
            from: null,
            last_page: null,
            links: [],
            path: null,
            per_page: 10,
            to: null,
            total: 0
        }
    },
    songsRedraw: false,
    paginatorHasChanged: false,
};

function addOneToData(data, payload) {
    let filteredData = data.filter(function (item) {
        return item.id !== payload.id;
    });
    return [payload, ...filteredData];
}

function addOneToPaginator(paginator) {
    paginator.meta.total = paginator.meta.total + 1;
    let addThreshold = paginator.meta.last_page * paginator.meta.per_page;

    if (addThreshold < paginator.meta.total) {
        paginator.meta.last_page++;
        let beforeLastIndex = paginator.meta.links.length - 1;

        paginator.meta.links = [
            ...paginator.meta.links.slice(0, beforeLastIndex),
            {
                "url": "?page=" + paginator.meta.last_page + "&per_page=" + paginator.meta.per_page,
                "label": paginator.meta.last_page + '',
                "active": false
            },
            {
                "url": "?page=2&per_page=" + paginator.meta.per_page,
                "label": "Next",
                "active": false
            }
        ]
    }
    return paginator;
}


function removeOneToData(data, payload) {
    let filteredData = data.filter(function (item) {
        return item.id !== payload.id;
    });
    return [...filteredData];
}

function removeOneToPaginator(paginator) {
    paginator.meta.total = paginator.meta.total - 1;
    let removeThreshold = (paginator.meta.last_page - 1) * paginator.meta.per_page;

    console.log('removeOneToPaginator', removeThreshold, paginator.meta.total);

    if (removeThreshold >= paginator.meta.total) {
        paginator.meta.last_page--;
        let beforeLastIndex = paginator.meta.links.length - 1;

        paginator.meta.links = [
            ...paginator.meta.links.slice(0, beforeLastIndex - 1),
            ...paginator.meta.links.slice(beforeLastIndex),
        ]
    }
    return paginator;
}

export default (state = INITIAL_SONGS, action) => {
    switch (action.type) {
        case actionTypes.SET_SONGS:
            return {
                ...action.payload,
            }
        case actionTypes.ADD_SONG:
            return {
                ...state,
                data: addOneToData(state.data, action.payload),
                paginator: addOneToPaginator(state.paginator),
                paginatorHasChanged: true
            }
        case actionTypes.REMOVE_SONG:
            return {
                ...state,
                data: removeOneToData(state.data, action.payload),
                paginator: removeOneToPaginator(state.paginator),
                paginatorHasChanged: true
            }
        case actionTypes.SONGS_REDRAW:
            return {
                ...state,
                songsRedraw: action.payload.songsRedraw
            }
        case actionTypes.RESET_PAGINATOR_HAS_CHANGED:
            return {
                ...state,
                paginatorHasChanged: action.payload.paginatorHasChanged
            }
        default:
            return state;
    }
};