import {Auth} from "aws-amplify";
import {useEffect} from 'react'
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';

export default ({children, user, setUser, setJwtToken, loadingUser}) => {
    const location = useLocation()
    const history = useHistory()

    const dispatch = useDispatch();
    const {isLoaded, isLoading, currentUser} = user

    useEffect(() => {
        if (!isLoaded && !isLoading) {
            loadingUser()
            Auth.currentAuthenticatedUser().then(data => {
                // console.log("currentAuthenticatedUser", {
                //     'jwtToken': data.signInUserSession.idToken.jwtToken,
                //     'user': data.attributes.sub
                // });
                setJwtToken(data.signInUserSession.idToken.jwtToken)
                setUser(data, data.attributes.sub);
            }).catch(e => {
                console.error(e)
                if (!location.pathname.includes('login') && !(location.pathname.includes('register'))) {
                    history.push('/login')
                }
                setUser(null, null)
            })
        }

        if (isLoaded && !currentUser) {
            if (!location.pathname.includes('login') && !(location.pathname.includes('register'))) {
                history.push('/login')
            }
        } else if (isLoaded) {
            if (location.pathname.includes('login') || (location.pathname.includes('register'))) {
                history.push('/')
            }
        }

    }, [isLoaded, isLoading])


    return (
        children
    )
}
