import React, { useState, useEffect } from "react";

const defaultLanguage = "english";
const LanguageContext = React.createContext(defaultLanguage);

export const LanguageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    let currentLang = localStorage.getItem("language");
    !currentLang && localStorage.setItem("language", defaultLanguage);
    !currentLang && (currentLang = defaultLanguage);

    setLanguage(currentLang);
  }, []);

  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  );
};

export const LanguageContextConsumer = (props) => {
  const { Component, children } = props

  return (
    <LanguageContext.Consumer>
      {(language) => <Component language={language} {...props} >{children}</Component>}
    </LanguageContext.Consumer>
  );
};
