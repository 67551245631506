import React, {useEffect} from 'react'
import {connect, useDispatch} from "react-redux";
import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/solid'
import {resetPaginatorHasChanged, setSongs} from "../../store/actions/Actions";
import {getSongs} from "../../libraries/DnaApi";

function Paginator({paginator, paginatorHasChanged}) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (paginatorHasChanged) {
            dispatch(resetPaginatorHasChanged());
        }
    }, [paginatorHasChanged]);

    const onClickPaginatorLink = async (url) => {
        console.log('onClickPaginatorLink ', url);
        if (!url) {
            return false;
        }

        const params = new Proxy(new URLSearchParams(url), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.page && paginator.meta.current_page !== params.page) {
            paginator.meta.current_page = params.page;
            await getSongs(paginator).then(async response => {
                dispatch(setSongs(response));
            });
        }
        return false;

    }


    return (
        <nav className=" justify-between border-t border-gray-200">
            <div className="flex items-center py-1 px-0 mx-0 sm:px-2 sm:mx-2">
                {paginator && paginator.meta.links.map((link, index, links) => {
                        if (index === 0) {
                            return <div className="-mt-px flex w-0 flex-1" key={`page-${link.label}`}>
                                {link.url ?
                                    <div onClick={() => onClickPaginatorLink(link.url)}
                                         className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        <ArrowLeftIcon className="mr-3 h-5 w-5" aria-hidden="true"/>Previous
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        } else if (index + 1 === links.length) {
                            return <div className="-mt-px flex w-0 flex-1 justify-end" key={`page-${link.label}`}>
                                {link.url ?
                                    <div onClick={() => onClickPaginatorLink(link.url)}
                                         className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        Next<ArrowRightIcon className="ml-3 h-5 w-5" aria-hidden="true"/>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        } else {
                            return <div className="sm:-mt-px sm:flex" key={`page-${link.label}`}>
                                <div onClick={() => onClickPaginatorLink(link.url)}
                                     className={`cursor-pointer inline-flex items-center border-t-2 border-transparent px-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
                               ${link.active
                                         ? "border-white text-white"
                                         : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"}
                               `}
                                >
                                    {link.label}
                                </div>
                            </div>
                        }
                    }
                )}
            </div>
        </nav>
    )
}

export default connect(state => {
    return {
        paginator: state.songs.paginator,
        paginatorHasChanged: state.songs.paginatorHasChanged
    };
})(Paginator)