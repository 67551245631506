import React from 'react';
import {Dna} from "react-loader-spinner";

function PlayerLoader({type = 'audio', message, height, width, inline = false}) {

    return (
        <div className={`mx-auto align-middle flex justify-center items-center ${!inline && "flex-col"}`}>
            <Dna color="#666" height={height} width={width}/>
            <p className="pt-1"> {message ? message : 'One moment please'} </p>
        </div>
    )

}

export default PlayerLoader;