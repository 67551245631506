import axios from "axios";
import {store} from '../store/Store';
import * as config from "./../configs/Config";
import {Auth} from "aws-amplify";

const getAxiosInstance = async () => {
    try {
        const state = store.getState();
        const instance = axios.create({
            baseURL: config.API_URL,
            headers: {
                'Authorization': 'Bearer ' + state.auth.jwtToken,
                'X-Requested-With': 'XMLHttpRequest'
            },
            withCredentials: true,
        });

        return instance;

    } catch (err) {
        throw err;
    }
};


// function meiliSearch(e) {
//     client.index('catalogs')
//         .search(e.target.value, {limit: 10})
//         .then((res) => setResults(res.hits));
//
//     setIsOpen(true);
// }

export const search = async (lookup, noRetry) => {
    try {
        const axiosInstance = await getAxiosInstance();

        return await axiosInstance.get("/search/tracks?limit=10&q=" + lookup)
            .then((response) => {
                return response.data;
            }).catch(function (err) {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return search(lookup, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        await errorHandler(err);
    }
};

export const getSongs = async (paginator, noRetry) => {
    try {
        const axiosInstance = await getAxiosInstance();
        let requestBody = {
            params: {
                page: paginator.meta.current_page,
                per_page: paginator.meta.per_page
            }
        }
        return await axiosInstance.get("/me", requestBody)
            .then((response) => {
                return response.data;
            }).catch(function (err) {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return getSongs(paginator, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        await errorHandler(err);
    }
};


export const addCatalog = async (catalogId, noRetry) => {
    try {
        let formData = new FormData();
        formData.append('catalog_id', catalogId);

        const axiosInstance = await getAxiosInstance();
        return await axiosInstance.post("/catalogs", formData)
            .then((response) => {
                return response.data.data;
            }).catch(function (err) {
                if (!noRetry) {
                    return refreshAndRetry(err, () => {
                        return addCatalog(catalogId, true)
                    })
                        .catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        console.error(err);
    }
};


export const deleteSong = async (song, noRetry) => {
    try {
        let endpoint = null;
        if (song.type === 'song') {
            endpoint = "/songs/" + song.id;
        } else if (song.type === 'catalog') {
            endpoint = "/catalogs/" + song.id;
        } else {
            await errorHandler('Invalid song.type ' + song.type);
        }

        const axiosInstance = await getAxiosInstance();
        return await axiosInstance.delete(endpoint)
            .then((response) => {
                if (response.status === 204) {
                    return Promise.resolve(true);
                } else {
                    errorHandler('Failed to remove song');
                    return Promise.resolve(false);
                }
            }).catch(function (err) {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return deleteSong(song, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        errorHandler(err)
    }
};

export const prepareS3Upload = async (file, noRetry) => {
    try {
        let formData = new FormData();
        formData.append('filename', file.name)
        formData.append('filetype', file.type)

        const axiosInstance = await getAxiosInstance();
        return await axiosInstance.post('/upload/prepare', formData)
            .catch((err) => {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return prepareS3Upload(file, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        await errorHandler(err);
    }
}

export const uploadToS3 = async (url, fields, file) => {
    try {
        const formData = new FormData();

        Object.entries({...fields, file}).forEach(([key, value]) => {
            formData.append(key, value);
        });
        return await fetch(url, {method: 'POST', body: formData})
            .catch((err) => {
                errorHandler(err);
            });
    } catch (err) {
        await errorHandler(err);
    }
}

export const storeSong = async (url, filename, noRetry) => {
    try {
        let formData = new FormData();
        formData.append('url', url);

        if (filename) {
            formData.append('filename', filename); //optional
        }

        const axiosInstance = await getAxiosInstance();
        return await axiosInstance.post('/songs', formData)
            .then((response) => {
                return response.data.data;
            }).catch(function (err) {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return storeSong(url, filename, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        await errorHandler(err);
    }

}


export const refreshToken = async (callback) => {
    try {
        console.log('====> refreshToken ====>');
        const cognitoUser = await Auth.currentAuthenticatedUser();
        console.log('====> refreshToken ====> cognitoUser', cognitoUser);
        if (cognitoUser) {
            const currentSession = cognitoUser.signInUserSession;
            console.log('====> refreshToken ====> currentSession', currentSession);
            return cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
                console.log('====> refreshToken ====> cognitoUser.refreshSession', session.idToken.jwtToken);
                const state = store.getState();
                state.auth = {jwtToken: session.idToken.jwtToken};
                //setJwtToken(session.idToken.jwtToken);

                console.log('====> refreshToken ====> cognitoUser.refreshSession before callback');
                setTimeout(() => {
                    console.log('====> refreshToken ====> cognitoUser.refreshSession execute callback');
                    callback();
                }, 1000);

            });
        } else {
            throw new Error("====> refreshToken ====> cognitoUser is null");
        }
    } catch (err) {
        console.error('Error in refreshToken : ' + err);
        await errorHandler('Error in refreshToken : ' + err);
        throw new Error(err);
    }
}


export const fetchData = async (url, noRetry) => {
    try {
        const axiosInstance = await getAxiosInstance();

        return axiosInstance.get(url, {})
            .then(function (response) {
                return response;
            }).catch((err) => {
                if (!noRetry) {
                    refreshAndRetry(err, () => {
                        return fetchData(url, true)
                    }).catch((err) => errorHandler(err));
                } else {
                    errorHandler(err);
                }
            });
    } catch (err) {
        await errorHandler(err);
    }
};


export const refreshAndRetry = async (err, callback) => {
    try {
        if (err && err.response && err.response.status && err.response.status === 401) {
            console.log('Starting refreshingToken process');
            refreshToken(() => callback())
                .catch(() => {
                    errorHandler('Authentication problem, redirect to login');
                    window.location = '/login';
                });
        } else if (err.response.status === 500) {
            await errorHandler('Server problem, redirect to error page');
            //window.location = '/login';
        }
    } catch (err) {
        await errorHandler('refreshAndRetry problem, redirect to login');
        //window.location = '/login';
    }
}


export const errorHandler = async (err) => {
    try {
        console.error('errorHandler', err);
    } catch (err) {
        console.error(err);
    }
}