import React from 'react';
import {Audio} from "react-loader-spinner";

function HitlabLoader({type, message, height, width, inline = false}) {

    return (
        <div className={`mx-auto flex justify-center items-center ${!inline && "flex-col"}`}>
            <Audio type={type} color="#666" height={height} width={width}/>
            <p className="pt-1"> {message ? message : 'One moment please'} </p>
        </div>
    )

}

export default HitlabLoader;